<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Vendedores - Calendario</h4>
            <div class="small text-muted">Programación de las actividades de un vendedor</div>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row>
        <b-col sm="6">
          <b-card header-tag="header" footer-tag="footer">
            <FullCalendar defaultView="dayGridMonth" 
                          :plugins="calendarPlugins"                           
                          :locale="calendarOptions.locale"
                          :weekends="true" 
                          :events="calendarOptions.event"/>
          </b-card>                            
        </b-col>
        <b-col sm="6">
          
          <b-card header-tag="header" footer-tag="footer">
            <b-row class="mb-3">
              <b-col md="6" v-if="sellers.staff">                
                <b-avatar :src="sellers.staff.image"
                          v-if="sellers.staff.image">
                </b-avatar>
                <b-avatar v-else                                             
                          variant="dark">
                </b-avatar>     
                &nbsp;<b>{{sellers.name}}</b>
              </b-col>
              <b-col md="6">
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar actividad">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>
              </b-col>
            </b-row>
          
            <b-row>
              <b-col md="12">                        
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"                        
                        v-if="table.items.length">

                    <template v-slot:cell(date_start)="data">
                      {{moment(data.item.date_start).format('DD/MM/YYYY')}}                                                                               
                    </template>

                    <template v-slot:cell(date_end)="data">
                      {{moment(data.item.date_end).format('DD/MM/YYYY')}}                                                                               
                    </template>

                    <template v-slot:cell(assignment)="data">
                      <div v-if="data.item.zone">
                        <b>Visita:</b> {{data.item.zone.name}}
                      </div>
                      <div v-else>
                        {{data.item.activity}}
                      </div>
                    </template>                    

                    <template v-slot:cell(observations)="data">
                      <div v-if="data.item.observation">                      
                        <div v-if="data.item.observation.length>30" :title="data.item.observation">
                          {{data.item.observation.substring(0,30) + "..."}}
                        </div>
                        <div v-else>
                          {{data.item.observation}}
                        </div>                                                                    
                      </div>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                
              </b-col>
              <b-col md="12" class="mt-3">
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" 
                                v-if="table.items.length" />
                </nav>                
              </b-col>
            </b-row> 
          
          </b-card>          
          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>
            </b-row>
          </b-card>          
        </b-col>
      </b-row>      
      
      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Tipo" class="text-center">
              <b-form-radio-group
                v-model="crud.form.type.selected"
                :options="crud.form.type.options"
                name="radios-type"
                size="md"
              ></b-form-radio-group>
            </b-form-group>            
          </b-col>

          <b-col md="6">
            <b-form-group label="Fecha Inicio" class="text-center">
              <b-form-datepicker size="sm" v-model="crud.form.date_start" placeholder="Fecha Inicio" local="es"></b-form-datepicker>                                    
            </b-form-group>            
          </b-col>
          <b-col md="6">
            <b-form-group label="Fecha Fin" class="text-center">              
              <b-form-datepicker size="sm" v-model="crud.form.date_end" placeholder="Fecha Fin" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>

          <b-col md="12">            
            <FindObject type="sellersZone" 
                        @select-object="loadSellersZone($event)" 
                        :valueID="crud.form.zone_id" 
                        :where="conditionFindZone"
                        v-show="crud.form.type.selected == 'zone'"/>                    
                        
            <b-form-group label="Actividad" 
                          description="Actividad que se ve afectado un vendedor cuando no tiene zonas asignadas"
                          v-show="crud.form.type.selected == 'activity'">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.activity"                            
                            placeholder="Ingresar la actividad">
              </b-form-input>
            </b-form-group>            
          </b-col>                       

          <b-col md="12">
            <b-form-group label="Observaciones">
              <b-form-textarea                                
                v-model="crud.form.observation"
                size="sm"
                placeholder="Ingresa una observación"
              ></b-form-textarea>
            </b-form-group>          
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Profiles from '@/config/profiles'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment' 
  import FindObject from '@/components/inc/find/findObject'

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'    
  import esLocale from '@fullcalendar/core/locales/es';
  import interactionPlugin from '@fullcalendar/interaction';

  export default {
    components: {          
      FindObject,
      FullCalendar
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.VENDEDORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudSellersCalendar',
          elements: {}
        }, 
        calendarPlugins: [ dayGridPlugin, interactionPlugin ],        
        calendarOptions: {
          locale: esLocale,
          event: []
        },
        sellersID: 0,
        sellers: {},
        table : {
          items: [],
          fields: [            
            {key: 'date_start', label: 'Fecha Inicio', sortable: true},
            {key: 'date_end', label: 'Fecha Fin', sortable: true},
            {key: 'assignment', label: 'Asignación', class:"text-center"},
            {key: 'observations', label: 'Observaciones', class:"text-center"},
            {key: 'f_action', label:''},
          ],                
          currentPage: 1,
          perPage: 10,            
        },  
        crud: {
          form: {
            date_start: moment().format('YYYY-MM-DD'),
            date_end: moment().add(7, 'days').format('YYYY-MM-DD'),
            type: {
              selected: 'zone',
              options: [
                { text: 'Zona', value: 'zone' },
                { text: 'Actividad', value: 'activity' }
              ]
            },                  
            zone_id: 0,
            zone: null,
            activity: "",            
            observation: '',            
            sellers_id: 0,
          }
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        }           
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted () {            
      this.sellersID = this.$route.params.sellersID

      moment.locale('es');
      this.load()      
    },      
    computed: {
      conditionFindZone(){
        return [{field: 'sellers_id', condition: this.sellersID}];
      }
    },
    methods: {
      getRowCount (items) {
        return items.length
      },      
      load() {
        this.loadCalendar()
        this.loadCalendarBySellers()
      },
      loadCalendar() {           
        let loader = this.$loading.show();

        this.calendarOptions.event = []

        var result = serviceAPI.mostrarVendedor(this.sellersID)

        result.then((response) => {                    
          var data = response.data          
          this.sellers = data              
                          
          data.calendars.forEach(element => {
            var activityTitle = ''
            var colorHexa = ''
            if(element.zone_id) {
              activityTitle = 'Visita: ' + element.zone.name
              colorHexa = '#4caf5073'
            }
            else { 
              activityTitle = element.activity
              colorHexa = '#e613047d'              
            }

            var event = {}

            event = {
              title: activityTitle,
              start: element.date_start,
              end: element.date_end,
              color: colorHexa,
            }            
            this.calendarOptions.event.push(event)            
          }); 
          
          loader.hide()
        })
        .catch(error => {          
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });          
      },
      loadCalendarBySellers() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerCalendarioBySellers({sellers_id: this.sellersID})

        result.then((response) => {                    
          var data = response.data
          this.table.items = data
          loader.hide()
        })     
        .catch(error => {          
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      add() {        
        this.crud.form.id = 0
        this.crud.form.type.selected = 'zone'
        this.crud.form.sellers_id = this.sellersID
        this.crud.form.zone_id = 0
        this.crud.form.zone = null
        this.crud.form.activity = ''
        this.crud.form.observation = ''
        this.setNextDate()
        
        this.modal.form.title = "Agregar Actividad"
        this.modal.form.active = true
      },
      edit(item) {               
        this.crud.form.id = item.id
        this.crud.form.sellers_id = item.sellers_id
        if(item.zone_id) {
          this.crud.form.type.selected = 'zone'
          this.crud.form.zone_id = item.zone_id                 
          this.crud.form.zone = item.zone          
          this.crud.form.activity = ''        
        } else {
          this.crud.form.type.selected = 'activity'
          this.crud.form.zone_id = 0
          this.crud.form.zone = null
          this.crud.form.activity = item.activity          
        }                                
        this.crud.form.observation = item.observation
        this.crud.form.date_start = item.date_start
        this.crud.form.date_end = item.date_end

        this.modal.form.title = "Editar Actividad"
        this.modal.form.active = true
      },   
      remove(item) {
        this.crud.form.id = item.id
        if(item.zone_id) {
          this.crud.form.name = "Zona: " + item.zone.name        
        } else {
          this.crud.form.name = "Actividad: " + item.activity        
        }

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Actividad',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarCalendario(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarCalendario(this.crud.form);
        } else {
          var result = serviceAPI.agregarCalendario(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadSellersZone (object) {        
        if(object){
          this.crud.form.zone = object
          this.crud.form.zone_id = object.id        
        } else {
          this.crud.form.zone = null
          this.crud.form.zone_id = 0
        }
      },  
      setNextDate() {        
        if(this.table.items.length){
          this.crud.form.date_start = moment(this.table.items[0].date_end).format('YYYY-MM-DD')
          this.crud.form.date_end = moment(this.table.items[0].date_end).add(7, 'days').format('YYYY-MM-DD')
        } else {
          this.crud.form.date_start = moment().format('YYYY-MM-DD')
          this.crud.form.date_end = moment().add(7, 'days').format('YYYY-MM-DD')
        }
      }           
    }
  }
</script>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>